<template>
    <div class="equipmentManufacturers">
        <jy-query ref="checkForm" :model="formInline">
            <jy-query-item label="厂商名称:" prop="name">
                <el-input v-model="formInline.name" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="厂家类型:" prop="type">
                <el-select v-model="formInline.type" placeholder="请选择">
                    <el-option v-for="item in cjOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('reset')">重置</el-button>
                <!-- <el-button type="primary" @click="onexport">导出</el-button>-->
                <el-row>
                    <el-button type="primary" @click="add" v-if="btnexist('insert')">新增</el-button>
                    <el-button type="primary" @click="batchdelete" v-if="btnexist('batchDelete')">批量删除</el-button>
                    <!-- <el-button type="primary" @click="batchimport">导入</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <div class="jy_table">
            <jy-table ref="multipleTable" :data="lists" tooltip-effect="dark" @select-all="selectallchange" header-cell-class-name="bg_color" border @select="selectchange" style="width: 100%">
                <jy-table-column type="index" width="60" label="序号">
                </jy-table-column>
                <jy-table-column type="selection" width="40">
                </jy-table-column>
                <jy-table-column prop="name" label="厂商名称" min-width="100">
                </jy-table-column>
                <jy-table-column label="厂商类型" min-width="100">
                    <template slot-scope="scope">
                        {{scope.row.type|type}}
                    </template>
                </jy-table-column>
                <jy-table-column prop="createTime" label="创建日期" min-width="100">
                </jy-table-column>
                <jy-table-column prop="remark" label="备注" min-width="200" class-name="align_left">
                </jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
            </jy-pagination>
        </div>
        <add-dialog ref="dialog" @updateList="getlist"></add-dialog>
    </div>
</template>
<script>
import adddialog from '@/components/pages/admin/basicInformation/basicData/equipmentManagement/addequipment'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            formInline: {
                name: '',
                type: '',
            },
            cjOptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: 0,
                    label: 'GPS设备',
                },
                {
                    value: 1,
                    label: '客流设备',
                },
                {
                    value: 2,
                    label: 'DVR',
                },
                {
                    value: 3,
                    label: 'POS机',
                },
            ],
            lists: [
                {
                    name: '',
                    type: '',
                    createTime: '',
                    remark: '',
                },
            ],
            // 批量删除
            batchdeleteList: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],

            btnRoute:'/admin/basicInformation/basicData/equipmentManufacturers',
        }
    },
    mixins:[btnMixins],
    components: {
        'add-dialog': adddialog,
    },
    filters: {
        type(val) {
            let v = ''
            switch (val) {
                case '0':
                    v = 'GPS设备'
                    break
                case '1':
                    v = '客流设备'
                    break
                case '2':
                    v = 'DVR'
                    break
                case '3':
                    v = 'POS机'
                    break
            }
            return v
        },
    },
    created() {
        this.getlist()
        this.setOperateList()
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                    isShow:()=>{
                        return this.btnexist('update')
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('delete')
                    },
                },
            ]
            this.operateList = l
        },
        getlist() {
            let url = '/basedevicevendor/list'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.lists = detail.list
                this.total = detail.total
            })
        },
        deleteRecord(brandIdAry) {
            this.$http
                .post('/basedevicevendor/delete', brandIdAry)
                .then(res => {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    })
                    this.getlist()
                })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 导出
        onexport() {
            console.log('导出')
        },
        // 新增
        add() {
            this.$refs.dialog.init()
            console.log('新增')
        },
        // 批量删除
        batchdelete() {
            if (!this.batchdeleteList.length) {
                this.$message({
                    type: 'warning',
                    message: '请先选择删除项',
                })
                return false
            }
            this.$confirm('是否删除选中厂商?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option = []
                    this.batchdeleteList.forEach(item => {
                        option.push(item.deviceVendorId)
                    })
                    this.deleteRecord(option)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        // 批量导入
        batchimport() {},

        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },

        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该厂商?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let params = []
                    params.push(row.deviceVendorId)
                    this.deleteRecord(params)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init(row, 'update')
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
    },
}
</script>
<style lang="scss" scoped="equipmentManufacturers">
</style>
<style lang="scss">
</style>
